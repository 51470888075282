import React from "react"
import SEO from "../components/seo"
import presets from "../utils/presets"
import Cards from "../components/cards"
// import MonitorCards from "../components/monitorCards"
import Footer from "../components/footer"
import Layout from "../components/layout"
import { colors } from "../utils/presets"
import typography from "../utils/typography"
import Companies from "../components/companies"
import MastheadBg from "../components/masthead-bg"
import MastheadContent from "../components/masthead"
import { rhythm, scale, options } from "../utils/typography"
import { vPHd, vPVHd, vPVVHd } from "../components/gutters"

// const IndexPage = ({ location }) => (
class IndexPage extends React.Component {

  componentDidMount() {

  }

  render() {
    const { location } = this.props;
    return (
      <div className="home-page" css={{ position: `relative` }}>
        <MastheadBg />
        <Layout location={location}>
          <SEO title="VLAN.CN官网-华域安网_细节精致到位" />
          <MastheadContent />
          <div css={styles.contact}>
            <main css={styles.main}>
              <div css={styles.cards}>
                <Cards location={location} />
                {/* <div css={styles.cards} style={{ background: 'rgba(249, 249, 249, 0.5)', boxShadow: 'none'}}>
                  <MonitorCards />
                </div> */}
                
              </div>
              <Footer />
            </main>
          </div>
        </Layout>
      </div>
    )
  }
}

const styles = {
  cards: {
    background: `#fff`,
    borderRadius: presets.radiusLg,
    boxShadow: `0 5px 20px rgba(25, 17, 34, 0.1)`,
    display: `flex`,
    flex: `0 1 auto`,
    flexWrap: `wrap`,
    transform: `translateZ(0)`,
    width: `100%`,
  },
  cardHeadLine: {
    ...scale(2 / 5),
    lineHeight: 1.2,
    marginTop: 0,
    [presets.Tablet]: {
      fontSize: scale(1 / 10).fontSize,
    },
    [presets.Desktop]: {
      fontSize: scale(3 / 10).fontSize,
    },
    [presets.VHd]: {
      fontSize: scale(5 / 10).fontSize,
    },
    [presets.VVHd]: {
      fontSize: scale(7 / 10).fontSize,
    },
  },
  contact: {
    flex: `0 0 100%`,
    maxWidth: `100%`,
    padding: rhythm(presets.gutters.default / 2),
    paddingBottom: 0,
    paddingTop: 50,
    [presets.Hd]: { padding: vPHd, paddingTop: 50, paddingBottom: 0 },
    [presets.VHd]: { paddingLeft: vPVHd, paddingRight: vPVHd },
    [presets.VVHd]: { paddingLeft: vPVVHd, paddingRight: vPVVHd },
  },
  futuraParagraph: {
    fontFamily: options.headerFontFamily.join(`,`),
    marginBottom: 0,
  },
  licence: {
    color: colors.ui.bright,
    fontFamily: typography.options.headerFontFamily.join(`,`),
    fontSize: `0.5rem`,
    marginLeft: `auto`,
    textAlign: `right`,
  },
  main:{
    display: `flex`,
    flexDirection: `row`,
    flexWrap: `wrap`,
    justifyContent: `space-between`,
    paddingBottom: 0,
    "@media (min-width: 640px)": {
      paddingBottom: 0,
  },
  },
}

export default IndexPage
