import React from "react"
import { rhythm } from "../utils/typography"
import presets, { colors } from "../utils/presets"
import particlesJS from "../lib/particles"

const vPOff = rhythm(presets.gutters.default - presets.logoOffset)
const vPHdOff = rhythm(presets.gutters.HdR - presets.logoOffset)
const vPVHdOff = rhythm(presets.gutters.VHdR - presets.logoOffset)
const vPVVHdOff = rhythm(presets.gutters.VVHdR - presets.logoOffset)

const cover = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  position: `absolute`,
}

const generateBg = () => {
  // console.log(particlesJS);
  particlesJS("particles-js", {
    "particles": {
      "number": {
        "value": 50,
        "density": {
          "enable": true,
          "value_area": 400
        }
      },
      "color": {
        "value": "#fff"
      },
      "opacity": {
        "value": 0.2,
        "random": false,
        "anim": {
          "enable": false,
        }
      },
      "size": {
        "value": 2,
        "random": false,
        "anim": {
          "enable": false,
        }
      },
      "line_linked": {
        "enable": true,
        "distance": 150,
        "color": "#fff",
        "opacity": 0.3,
        "width": 1
      },
      "move": {
        "enable": true,
        "speed": 2,
        "direction": "none",
        "random": true,
        "straight": false,
        "out_mode": "bounce",
        "bounce": false,
        "attract": {
          "enable": false,
        }
      }
    },
    "interactivity": {
      "detect_on": "canvas",
      "events": {
        "onhover": {
          "enable": true,
          "mode": "grab"
        },
        "onclick": {
          "enable": true,
          "mode": "push"
        },
        "resize": true
      },
      "modes": {
        "grab": {
          "distance": 140,
          "line_linked": {
            "opacity": 0.6
          }
        },
        "push": {
          "particles_nb": 4
        },
      }
    },
    "retina_detect": true
  }, window);
}

// const MastheadBg = () => {
class MastheadBg extends React.Component {
  componentDidMount() {
    generateBg()
  }
  
  render() {
    return (
      <div
        className="masthead-bg"
        css={{
          ...cover,
          overflow: `hidden`,
          zIndex: -1,
        }}
      >
        <div id="particles-js"></div>
        <div
          className="masthead-bg-left-fill"
          css={{
            ...cover,
            right: `auto`,
            width: vPOff,
            zIndex: -10,
            background: 'aliceBlue',
            [presets.Hd]: {
              width: vPHdOff,
            },
            [presets.VHd]: {
              width: vPVHdOff,
            },
            [presets.VVHd]: {
              width: vPVVHdOff,
            },
          }}
        />
        <svg
          viewBox="0 0 10 10"
          preserveAspectRatio="xMinYMin slice"
          className="masthead-bg-left"
          css={{
            ...cover,
            left: vPOff,
            zIndex: -2,
            [presets.Hd]: {
              left: vPHdOff,
            },
            [presets.VHd]: {
              left: vPVHdOff,
            },
            [presets.VVHd]: {
              left: vPVVHdOff,
            },
            width: `100%`,
            height: `100%`,
          }}
        >
          <polygon fill={'aliceBlue'} points="-5,-5 15,15 -5,15 " />
        </svg>
        <style>
          {`
              .masthead-bg-right-light {
                fill: ${colors.skyDark};
              }
              @media (max-width: 650px),
              (max-width: 768px) and (orientation:portrait) {
                .masthead-bg-right {
                  width: calc(160% + 4vh);
                }
              }
              ${presets.Phablet} {
                .masthead-bg-right {
                  width: calc(160% + 4vh);
                  top: 0;
                }
              }
              @media (min-width: 910px) {
                .masthead-bg-right {
                  width: calc(125% + 4vh);
                }
              }
              ${presets.Desktop}  {
                .masthead-bg-right {
                  width: 110%;
                }
    
                .masthead-bg-right-light {
                  fill: ${'lightSkyBlue'};
                }
              }
              ${presets.Hd}  {
                .masthead-bg-right {
                  width: calc(100%);
                }
              }
            `}
        </style>
        <svg
          viewBox="0 0 10 10"
          preserveAspectRatio="xMidYMin meet"
          className="masthead-bg-right"
          css={{
            ...cover,
            width: `calc(180% - + 4vh)`,
            height: `100%`,
            zIndex: -1,
            top: `2%`,
            //transition: `width 100ms linear`,
          }}
        >
          <svg
            x="-15%"
            y="-10%"
            style={{
              overflow: `visible`,
            }}
          >
            <rect
              className="masthead-bg-right-light"
              width="10000%"
              height="10000%"
              fill={'lightSkyBlue'}
              transform="rotate(45 100 50) translate(0 0)"
            />
            <rect
              className="masthead-bg-left-dark"
              width="10000%"
              height="10000%"
              fill={colors.skyDark}
              transform="rotate(45 100 50) translate(1.25 0)"
            />
            {/*<polygon fill="blue" points="0,10 10,0 10,10" />*/}
          </svg>
        </svg>
      </div>
    )
  }
}

export default MastheadBg
